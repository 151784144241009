<template>
  <div class="page-wrapper">
    <!-- <div class="sticky-tab"> -->
    <van-sticky class="tabs-wrapper">
      <van-tabs
        v-model="activeTab"
        :offset-top="navHeight"
        @change="onTabChange"
      >
        <van-tab
          :title="item.name"
          :name="item.type"
          v-for="(item, index) in tabs"
          :key="index"
        >
        </van-tab>
      </van-tabs>
    </van-sticky>
    <!-- </div> -->
    <div
      class="scroll-wrapper"
      id="scroll-wrapper"
      ref="scroll"
      :style="{ height: showNav ? 'calc(100vh - 90px)' : 'calc(100vh - 44px)' }"
    >
      <ul class="news-list">
        <li
          class="news-item"
          @click="navToDetail(item)"
          v-for="(item, index) in newsList"
          :key="index"
        >
          <div class="news-title">
            <!-- 【{{ formatLevelText(item) }}】 -->
            {{ item.policyFileName }}
          </div>
          <div class="news-info">
            <span> {{ item.publishAgency || "-" }} </span>
            <span> {{ item.onlineTime.split(" ")[0] }}</span>
          </div>
        </li>
        <no-more v-if="noMore" />
      </ul>
      <van-empty v-if="noData" description="暂无数据" />
    </div>
  </div>
</template>

<script>
import { getNewListByType } from "@/api/policy/news";
import BScroll from "better-scroll";
import NoMore from "@/components/NoMore";

export default {
  data() {
    return {
      activeTab: "0",
      tabs: [
        { name: "综合新闻", type: "0" },
        { name: "人才政策", type: "1" },
        { name: "公示公告", type: "2" },
        { name: "公开招聘", type: "3" },
      ],
      pageNum: 1,
      pageSize: 10,
      loading: false, //搜索状态
      noMore: false,
      noData: false,
      newsList: [],
      navHeight: 46,
      beforePullDown: true,
      isPullingDown: false,
      isPullUpLoad: false,
      bscroll: null,
    };
  },
  components: {
    NoMore,
  },
  watch: {
    // activeTab() {
    //   if (this.activeTab) {
    //     this.initPageData();
    //     this.getList();
    //   }
    // },
  },
  computed: {
    showNav() {
      return this.$store.state.global.showNav;
    },
  },
  created() {
    const { type } = this.$route.query;
    if (type) {
      this.activeTab = String(type);
    }
  },
  mounted() {
    // this.initScroll();
    this.$nextTick(() => {
      this.initScroll();
      // this.bscroll.refresh();
    });
    this.getList();
  },

  methods: {
    onTabChange(e) {
      this.activeTab = e;
      this.initPageData();
      this.getList();
    },
    initScroll() {
      this.bscroll = new BScroll(this.$refs.scroll, {
        pullUpLoad: true,
        pullDownRefresh: true,
        click: true,
      });
      this.bscroll.on("pullingDown", () => {
        this.handleRefresh();
      });
      this.bscroll.on("pullingUp", () => {
        this.handleScrollBottom();
      });
    },

    handleRefresh() {
      this.initPageData();
      this.getList();
      this.bscroll.finishPullDown();
    },
    //init
    initPageData() {
      this.pageNum = 1;
      this.loading = false;
      this.noData = false;
      this.noMore = false;
    },
    //滚到底
    handleScrollBottom() {
      this.bscroll.finishPullUp();
      if (this.loading || this.noMore) {
        return;
      }
      this.pageNum++;
      this.getList();
    },
    getList() {
      if (this.loading) return;
      this.loading = true;
      const params = {
        status: 5,
        fileType: this.activeTab,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      getNewListByType(params)
        .then((res) => {
          this.loading = false;
          if (!res) {
            this.noMore = true;
            this.noData = true;
            return;
          }
          if (this.pageNum == 1) {
            this.newsList = res.data.records || [];
          } else {
            this.newsList = [...this.newsList, ...res.data.records];
          }
          //判断nomore
          if (res && res.data.pages == res.data.current) {
            this.noMore = true;
          } else {
            this.noMore = false;
          }

          if (res.data.total == 0) {
            this.noData = true;
          } else {
            this.noData = false;
          }
          this.$nextTick(() => {
            this.bscroll.refresh();
          });
        })
        .catch(() => {
          this.$nextTick(() => {
            this.loading = false;
          });
        });
    },
    navToDetail(e) {
      this.$router.push({
        path: `/newsDetail/${e.idCode}`,
      });
    },
    formatLevelText(item) {
      let str;
      switch (item.policyLevel) {
        case "0":
          str = "全国";
          break;
        case "1":
          str = item.provinceName;
          break;
        case "2":
          str = item.cityName;
          break;
        case "3":
          str = item.countyName;
          break;

        default:
          break;
      }
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  height: 100%;
  position: relative;
  background: #f8f8f8;
}
.tabs-wrapper {
  position: relative;
  z-index: 99;
  background: #fff;
}
.scroll-wrapper {
  position: relative;
  // height: calc(100vh - 44px);
}
.loading {
  display: flex;
  padding: 0.2rem 0;
  align-items: center;
  justify-content: center;
}
.news-list {
  padding: 0.2rem 0.2rem 0;
  border-radius: 0.2rem;
  border-bottom: 1px solid transparent;
  .news-item {
    padding: 0.2rem 0;
    border-bottom: 1px solid #e5e5e5;
    &:nth-last-of-type(1) {
      border-bottom: none;
    }
  }
  .news-title {
    font-size: 0.32rem;
    color: #000000;
    line-height: 0.46rem;
    margin-bottom: 0.12rem;
    // @include ellipsis(2);
  }
  .news-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 0.28rem;
    color: #999999;
    line-height: 0.4rem;
    & > span {
      &:nth-last-of-type(1) {
        width: 2rem;
        flex: 0 0 2rem;
        text-align: right;
      }
    }
  }
}
</style>
